import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Seo/seo"
import PageBanner from "../components/PageBanner/pageBanner"
import Footer from "../components/Footer/footer";
import { TypographyStyle, GoogleFont } from 'react-typography'
import typography from '../utils/typography'
import { injectIntl, intlShape, FormattedHTMLMessage } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";

const ProductPage = (props) => (
  <Layout location={props.uri} isStatic={true}>
    <SEO title={props.intl.formatMessage({ id: 'polityka.title' })} />
    <TypographyStyle typography={typography} />
    <GoogleFont typography={typography} />
    <PageBanner title={props.intl.formatMessage({ id: 'polityka.title' })} />
    <div className="static-page-container">
      <div className="row">
        <div className="col-12">
          <h2>{props.intl.formatMessage({ id: 'polityka.subtitle' })}</h2>
          <h3>{props.intl.formatMessage({ id: 'polityka.definitions' })}</h3>
          <ol>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.definitions.a' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.definitions.b' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.definitions.c' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.definitions.d' />
            </li>
          </ol>
          <h3>{props.intl.formatMessage({ id: 'polityka.data' })}</h3>
          <ol>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.data.a' />
              <ul>
                <li><FormattedHTMLMessage tagName='p' id='polityka.data.a.a' /></li>
                <li><FormattedHTMLMessage tagName='p' id='polityka.data.a.b' /></li>
                <li><FormattedHTMLMessage tagName='p' id='polityka.data.a.c' /></li>
                <li><FormattedHTMLMessage tagName='p' id='polityka.data.a.d' /></li>
              </ul>
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.data.b' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.data.c' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.data.d' />
            </li>
          </ol>
          <h3>{props.intl.formatMessage({ id: 'polityka.cookies' })}</h3>
          <ol>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.cookies.a' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.cookies.b' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.cookies.c' />
            </li>
          </ol>
          <h3>{props.intl.formatMessage({ id: 'polityka.types' })}</h3>
          <ol>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.types.a' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.types.b' />
              <ul>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.types.b.a' />
                </li>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.types.b.b' />
                </li>
              </ul>
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.types.c' />
            </li>
          </ol>
          <h3>{props.intl.formatMessage({ id: 'polityka.aims' })}</h3>
          <ol>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.aims.a' />
              <ul>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.aims.a.a' />
                  <ul>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.a.a' />
                    </li>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.a.b' />
                    </li>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.a.c' />
                    </li>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.a.d' />
                    </li>
                  </ul>
                </li>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.aims.a.b' />
                  <ul>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.b.a' />
                    </li>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.b.b' />
                    </li>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.b.c' />
                    </li>
                  </ul>
                </li>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.aims.a.c' />
                  <ul>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.c.a' />
                    </li>
                  </ul>
                </li>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.aims.a.d' />
                  <ul>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.d.a' />
                    </li>
                  </ul>
                </li>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.aims.a.e' />
                  <ul>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.a.e.a' />
                    </li>
                  </ul>
                </li>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.aims.a.f' />
                </li>
              </ul>
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.aims.b' />
              <ul>
                <li>
                  <FormattedHTMLMessage tagName='p' id='polityka.aims.b.a' />
                  <ul>
                    <li>
                      <FormattedHTMLMessage tagName='p' id='polityka.aims.b.a.a' />
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <h3>
            {props.intl.formatMessage({ id: 'polityka.storing' })}
          </h3>
          <ol>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.storing.a' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.storing.b' />
            </li>
            <li>
              <FormattedHTMLMessage tagName='p' id='polityka.storing.c' />
            </li>
          </ol>
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
)

ProductPage.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(ProductPage)
